import React, {Component} from "react"
import BaseLayout from "../components/Layout/BaseLayout";
import {graphql, Link} from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import parse from "html-react-parser";
import styled from 'styled-components';
import {Modal} from "react-bootstrap";
import ReactPlayer from "react-player";

import video1 from "../assets/images/992067103.jpg";
import video2 from "../assets/images/992618038.jpg";
import video3 from "../assets/images/992730517.jpg";
import video4 from "../assets/images/992734493.jpg";

const videos = [video1, video2, video3, video4];

const VideoContainer = styled(Row)`

    background-color:rgba(106,159,181,0.2);
    padding:2em;
    border-radius:5px;    
    margin:10px 0;

    span {
      font-weight: bold;
    }
   
`;

const ImageHolder = styled.div`
    position:relative;
    margin:10px 0;
    width:auto;
    min-height:220px;
    display:block;
    cursor: pointer;    
  
    img {   
        width:100%;     
        position:absolute;
    }   
    
    &:hover {
        a {
         border-color: transparent transparent transparent #404040;
        }     
    }
    
`;

const ClearFix = styled.div`
    &:after{   
      content: '';
      display: block;
      clear:both;
    
    }
`;

const PlayButton = styled.a`

  position:absolute;
  
  left:50%;
  top:calc(200px / 2 - 57px);
  
  transform: translate(-50% -50%);
  
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 64px;

  border-color: transparent transparent transparent #FFF;
  transition: 100ms all ease;
  cursor: pointer;
  
  border-style: solid;
  border-width: 37px 0 37px 60px;

  &.paused {
    border-style: double;
    border-width: 0px 0 0px 60px;
  }

`;

const InlineButton = styled.a`
  display:inline-block;
  padding: .5rem 1rem;
  line-height:1.5;
  border-radius:5px; 
  color:#1e1a4a;
  &:hover {
      background-color: #1e1a4a;
      color:#FFF;
  }
`;

class PompeVideos extends Component {

    constructor(props){

        super(props);

        this.state= {
            show:false,
            showVideo:false,
            diseases: null,
            selectedVideo:'',
            videoTitle:''
        };

    }

    componentDidMount() {

        this.setState({
            show:true
        });

        const { data } = this.props;
    }


    playVideo(title, id){
        this.setState({
            showVideo:true,
            selectedVideo:id,
            videoTitle:title
        })
    }

    getCustomData(child, node) {

        const { data } = this.props;

        let customData = child.header[0].custom_data && child.header[0].custom_data;

        let obj = customData.filter((item)=>{
            let objName = Object.keys(JSON.parse(item))[0];
            return objName == node;
        });
        return (obj.length) ? JSON.parse(obj[0]) : null;
    }

    render() {

        const {data, location} = this.props;

        return (
            <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title} showSubNav={true}>

                <Modal
                    centered
                    show={this.state.showVideo}
                    onHide={()=>{
                        this.setState({showVideo:false});
                    }}
                    dialogClassName="modal-xl"
                    aria-labelledby="diseases-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="diseases-modal">
                            {this.state.videoTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactPlayer
                            width='100%'
                            height='50vh'
                            controls={true}
                            playing={this.state.showVideo}
                            muted={false}
                            url={'https://player.vimeo.com/video/'+this.state.selectedVideo}
                        />
                    </Modal.Body>
                </Modal>
                <section className="video-page">
                    {
                        parse(data.gravql.page.content)
                    }

                { data.gravql.page.children.map((child, x) => {

                    const t = this.getCustomData(child, "vimeo");
                    const template = this.getCustomData(child, "template");

                    return(
                        (template) ?  parse(child.content):

                            <VideoContainer key={x}>
                                <Col sm={12} lg={8}>
                                    <div key={child.title} id={x} className="offset">
                                        <h2>{child.title}</h2>
                                        {
                                            parse(child.content)
                                        }
                                        <br/>
                                        <InlineButton className={'btn btn-primary'} onClick={()=>this.playVideo(child.title, t.vimeo[0].id)}>Afspelen</InlineButton>
                                        <br/>
                                    </div>
                                </Col>
                                <Col sm={12} lg={4}>
                                    <ImageHolder onClick={()=>this.playVideo(child.title, t.vimeo[0].id)}>
                                        <img src={videos[x]} />
                                        <PlayButton/>
                                    </ImageHolder>
                                    <ClearFix/>
                                </Col>
                            </VideoContainer>

                )
                })}
                <div className={'text-center'}>
                    <br/><br/>
                    <Link to={'/lysosomale-stapelingsziekten/stapelingsziekten/pompe'} className="btn cta-btn">Lees meer over de ziekte van Pompe</Link>
                </div>
                </section>
            </BaseLayout>
        )
    }
}


export default PompeVideos;


export const query = graphql`
{
    currentBuildDate {
        currentDate
    }
    gravql {
        pages {

            title
            visible
            header {
                title
                body_classes
            }
            children {
                route
                title
                header {
                    body_classes
                    title
                }
                children {
                    route
                    title
                      children {
                        route
                        title
                    }
                }
            }
            route
        }
        page(route: "/pompe-videos") {
            title
            content
            route
            children {
                route
                title
                content
                header {
                    body_classes
                    custom_data
                    title
                }
            }
            imagesCropped(crop:true, width: "200", height:"200"){
                path
                filename
                url
                width
                height
            }
            metadata {
                name
                content
            }
        }
    }
}
`;
